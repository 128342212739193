import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import OAuth2Login from 'react-simple-oauth2-login';
import { userLogin } from '../../redux/user/actionCreator';

const LoginWithFacebook = ({ method }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSuccess = async (response) => {
    console.log(response);
    dispatch(
      userLogin(
        {
          ...response,
          provider: 'facebook',
        },
        navigate
      )
    );
  };
  const onFailure = (response) => console.error(response);
  return (
    <div>
      <img src={require('../../assets/facebook.png')} alt='' />
      <OAuth2Login
        authorizationUrl='https://www.facebook.com/dialog/oauth'
        buttonText={`${method} with Facebook`}
        responseType='token'
        clientId='5853824161336366'
        redirectUri='http://localhost:3000'
        onSuccess={onSuccess}
        onFailure={onFailure}
      />
    </div>
  );
};

export default LoginWithFacebook;
