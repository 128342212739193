import React from 'react';
import { Card } from './show-case-cad-style';

const ShowCaseCard = ({ url, title, price }) => {
  return (
    <Card bg={url}>
      <div>
        <h3>{title}</h3>
        <span>
          price: <b>{price}</b>
        </span>
        <button>buy now</button>
      </div>
    </Card>
  );
};

export default ShowCaseCard;
