import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Landing from './pages/landing/Landing';
import Login from './pages/login/Login';
import Registration from './pages/registration/Registration';
import { GoogleOAuthProvider } from '@react-oauth/google';
import GoogleClient from './gClientSecret.json';
import Vetting from './pages/vetting/Vetting';
import Popup from './pages/popup/Popup';

function App() {
  return (
    <GoogleOAuthProvider clientId={GoogleClient.web.client_id}>
      <div className='App'>
        <BrowserRouter>
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/vetting' element={<Vetting />} />
            <Route path='/popup' element={<Popup />} />
            <Route path='/registration' element={<Registration />} />
            <Route path='/' element={<Landing />} />
          </Routes>
        </BrowserRouter>
      </div>
    </GoogleOAuthProvider>
  );
}

export default App;
