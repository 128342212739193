import React, { useRef } from 'react';
import CustomSlider from '../../../global/components/CustomSlider/CustomSlider';
import ShowCaseCard from '../../../global/components/ShowCaseCard/ShowCaseCard';
import { Heading, MainContainer } from '../../../global/styles/style-component';
import './ShowCase.css';

const ShowCase = () => {
  const data = [
    {
      url: require('../../../assets/Rectangle8.png'),
      title: `Love's Philadelphia`,
      price: '$175.00',
    },
    {
      url: require('../../../assets/Rectangle45.png'),
      title: `Love's Philadelphia`,
      price: '$175.00',
    },
    {
      url: require('../../../assets/Rectangle48.png'),
      title: `Love's Philadelphia`,
      price: '$175.00',
    },
    {
      url: require('../../../assets/pexels-jafet-byrne-7008845.png'),
      title: `Love's Philadelphia`,
      price: '$175.00',
    },
    {
      url: require('../../../assets/pexels-jafet-byrne-7008845.png'),
      title: `Love's Philadelphia`,
      price: '$175.00',
    },
    {
      url: require('../../../assets/pexels-jafet-byrne-7008845.png'),
      title: `Love's Philadelphia`,
      price: '$175.00',
    },
  ];

  const innerRef = useRef(null);
  const onNextClickHandler = () => {
    const currentPos = innerRef.current.style.transform
      .split(',')[0]
      .split('(')[1]
      .replace('-', '')
      .replace('px', '');
    console.log(currentPos);
    if (currentPos < (data.length - 4) * 250)
      innerRef.current.style.transform = `translate3d(-${
        parseInt(currentPos) + 250
      }px, 0, 0)`;
    innerRef.current.style.transition = '1s';
  };
  const onPreClickHandler = () => {
    const currentPos = innerRef.current.style.transform
      .split(',')[0]
      .split('(')[1]
      .replace('-', '')
      .replace('px', '');
    console.log(currentPos);
    if (currentPos > 0) {
      innerRef.current.style.transform = `translate3d(${
        parseInt(currentPos) - 250
      }px, 0, 0)`;
      innerRef.current.style.transition = '1s';
    }
  };
  return (
    <MainContainer id='showCaseContainer' paddingVertical='3em'>
      <Heading>SHOWCASE</Heading>
      <h2
        className='text-left my-3'
        style={{ color: '#FB8501', textTransform: 'uppercase' }}
      >
        Categories of Campaigns
      </h2>
      <CustomSlider
        onNext={onNextClickHandler}
        onPre={onPreClickHandler}
        innerRef={innerRef}
      >
        {data.map(({ url, price, title }, index) => (
          <ShowCaseCard key={index} url={url} title={title} price={price} />
        ))}
      </CustomSlider>
    </MainContainer>
  );
};

export default ShowCase;
