import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './CustomSlider.css';

const CustomSlider = ({ children, innerRef, onNext, onPre }) => {
  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        padding: '0 10px ',
      }}
    >
      <div onClick={onPre} className='sliderBtnLeft'>
        <FontAwesomeIcon icon={faAngleLeft} size='2x' />
      </div>
      <div className='sliderBody'>
        <div
          ref={innerRef}
          style={{
            transform: 'translate3d(0px, 0px, 0px)',
          }}
          className='sliderContent'
        >
          {children}
        </div>
      </div>
      <div onClick={onNext} className='sliderBtnRight'>
        <FontAwesomeIcon icon={faAngleRight} size='2x' />
      </div>
    </div>
  );
};

export default CustomSlider;
