import { useGoogleLogin } from '@react-oauth/google';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userLogin } from '../../redux/user/actionCreator';

const LoginWithGoogle = ({ method = '' }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);
      dispatch(
        userLogin(
          {
            ...tokenResponse,
            provider: 'gmail',
          },
          navigate
        )
      );
    },
    onError: (errorResponse) => console.log(errorResponse),
  });
  return (
    <div>
      <img src={require('../../assets/google.png')} alt='' />
      <button onClick={() => googleLogin()}>{method} in with Google</button>
    </div>
  );
};

export default LoginWithGoogle;
