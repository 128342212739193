import React from 'react';
import Card from '../../../global/components/Card/Card';
import {
  CommonBtn,
  Heading,
  MainContainer,
} from '../../../global/styles/style-component';
import './Impactoverse.css';

const Impactoverse = () => {
  return (
    <MainContainer id='impactOverseaContainer' paddingVertical='3rem'>
      <Heading>WHO IS IMPACTOVERSE FOR?</Heading>

      <div className='impactOverseCardContainer'>
        <Card
          img={require('../../../assets/market 1 (1).png')}
          title='Brands & Agencies'
          detail={`Align campaigns with vetted causes  to drive customer loyalty and build brand community.`}
          fill='#77BFE0'
        >
          <button>Learn more</button>
        </Card>
        <Card
          img={require('../../../assets/market 2.png')}
          title='NPOs and Causes'
          detail={`Learn and collaborate to drive engagement and scale your impact.`}
          fill='#77BFE0'
        >
          <button>Learn more</button>
        </Card>
        <Card
          img={require('../../../assets/market 3.png')}
          title='Creators & Supporters'
          detail={`Showcase your NFTs, collab with a cause, build community.`}
          fill='#77BFE0'
        >
          <button>Learn more</button>
        </Card>
        <Card
          img={require('../../../assets/market 4.png')}
          title='Influencer'
          detail={`Skyrocket fan engagement in exciting new ways, scale your brand  impact.`}
          fill='#77BFE0'
        >
          <button>Learn more</button>
        </Card>
      </div>
      <CommonBtn margin={'3em 0 0 0'}>Sign Up</CommonBtn>
    </MainContainer>
  );
};

export default Impactoverse;
