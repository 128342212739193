import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LoginWithFacebook from '../../global/components/LoginWithFacebook';
import LoginWithGoogle from '../../global/components/LoginWithGoogle';
import { userRegister } from '../../redux/user/actionCreator';
import './Registration.css';

const Registration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({
    email: '',
    password: '',
    name: '',
    provider: 'email',
  });
  const onRegistrationHandler = () => {
    dispatch(userRegister(data, navigate));
  };
  return (
    <div className='registerContainer'>
      <div className='registerLeft'>
        <div>
          <div>
            <img
              src={require('../../assets/loginInWhite.png')}
              alt=''
              srcSet=''
            />
            <span>
              INFLUENCERS reach out HERE. We'll get you set up! BRANDS &
              AGENCIES or NON-PROFITS & CAUSES Register after Signing Up.
            </span>
          </div>
          <div>
            <h3>Let's Get Started</h3>
            <img src={require('../../assets/rightIcon.png')} alt='' srcSet='' />
          </div>
        </div>
      </div>
      <div className='registerRight'>
        <div>
          <h2>Create An Account</h2>
          <h5>
            Already have account?{' '}
            <span onClick={() => navigate('/login')}>SIGN IN</span>
          </h5>
          <form>
            <div>
              <label htmlFor='firstName'>First Name</label>
              <input type='text' name='firstName' id='firstName' />
            </div>
            <div>
              <label htmlFor='lastName'>Last Name</label>
              <input type='text' name='lastName' id='lastName' />
            </div>
            <div>
              <label htmlFor='username'>Username</label>
              <input type='text' name='username' id='username' />
            </div>
            <div>
              <label htmlFor='email'>Email/Username</label>
              <input type='email' name='email' id='email' />
            </div>
            <div>
              <label htmlFor='phoneNo'>Phone No</label>
              <input type='phone' name='phoneNo' id='phoneNo' />
            </div>
            <div>
              <label htmlFor='password'>Password</label>
              <input type='password' name='password' id='password' />
            </div>
            <div>
              <label htmlFor='confirmPassword'>Re-enter Password</label>
              <input
                type='password'
                name='confirmPassword'
                id='confirmPassword'
              />
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <input
                type='checkbox'
                id='vehicle3'
                name='vehicle3'
                value='Boat'
              />
              <label for='vehicle3'>
                I've read and agree with
                <span> Terms of Service and Privacy Policy</span>
              </label>
            </div>
            <button onClick={onRegistrationHandler}>SIGN Up</button>
          </form>
          <div className='registerLine'>
            <hr />
          </div>
          <div className='registerBtnContainer'>
            <LoginWithGoogle method={'Sign Up'} />
            <LoginWithFacebook method={'Sign Up'} />
            <div>
              <img src={require('../../assets/wallet.png')} alt='' />
              <button>Sign Up With Wallet</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
