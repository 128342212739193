import styled from 'styled-components';

export const TabNumber = styled.h1`
  background-color: #e67336;
  width: 50px;
  height: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 100%;
  @media (max-width: 1100px) {
    font-size: 1.7em;
  }
  @media (max-width: 885px) {
    font-size: 1.2em;
  }
  @media (max-width: 670px) {
    width: 40px;
    height: 40px;
  }
`;

export const Tab = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  transition: all 0.5s;
  opacity: ${({ active }) => (active ? '1' : '0.5')};

  & > span {
    text-align: center;
    font-weight: bold;
    flex: 1;
    @media screen and (max-width: 1100px) {
      font-size: 1em;
    }
    @media screen and (max-width: 786px) {
      font-size: 0.8em;
    }
    @media screen and (max-width: 526px) {
      font-size: 0.7em;
    }
    @media screen and (max-width: 438px) {
      display: none;
    }
  }
`;

export const FieldContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ noOfColumn }) => noOfColumn}, 1fr);
  padding: 2em 4em;
  gap: 1em;
  font-size: 0.9em;
  overflow-y: auto;
  & > div:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column: 1 / span ${({ noOfColumn }) => noOfColumn};
  }
  @media screen and (max-width: 570px) {
    grid-template-columns: 1fr;
    & > div:last-child {
      grid-column: 1;
    }
  }
  @media screen and (max-width: 438px) {
    padding: 2em;
  }
`;

export const FieldInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  & > div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0 3em;
    gap: 1em;
    font-size: 0.7em;
  }
  @media screen and (max-width: 570px) {
    & > div {
      grid-template-columns: 1fr;
    }
  }
`;
// ${({ icon }) => icon}
export const FieldItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > .radioContainer {
    display: flex;
    gap: 1em;
    & > div {
      display: flex;
      gap: 0.5em;
    }
  }
  & > input,
  & > textarea,
  & > select {
    width: 80%;
    border: 1px solid #aeaeae;
    padding: 7px 14px;
    border-radius: 4px;
    @media screen and (max-width: 1100px) {
      width: 100%;
    }
  }
  & > .uploadBtn {
    width: 70px;
    height: 70px;
    background-color: #e673366e;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    &::after {
      content: '';
      position: absolute;
      background-image: url(${require('../../assets/add.png')});
      background-size: 50% 50%;
      background-repeat: no-repeat;
      background-position: center;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }

    @media screen and (max-width: 1100px) {
      width: 60px;
      height: 60px;
    }
  }
`;
