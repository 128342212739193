import React from 'react';
import { MainContainer } from '../../../global/styles/style-component';
import Card from '../../../global/components/Card/Card';
import './WhatDoWeDo.css';

const WhatDoWeDo = () => {
  return (
    <>
      <MainContainer
        alignItem='center'
        id='whatDoWeDoContainer'
        paddingVertical='2em'
      >
        <h3>
          Impactoverse is a next-gen Impact Marketing Ecosystem synergizing Web2
          and 3 for measurable results.
        </h3>
        <div className='cardCollection'>
          <Card
            title={'WHAT DO WE DO?'}
            detail={`We help creators, brands, influencers and vetted causes collaborate to drive engagement, scale impact, and build brand community.`}
          />
          <Card
            img={require('../../../assets/market 1.png')}
            overLayColor={'#023047'}
            title={`Engagement for Results`}
            detail={`Brands and 
          creators drive business outcomes via collabs with vetted orgs. NPOs and 
          impact causes scale impact.`}
            fill={'#FCE887'}
          />
          <Card
            img={require('../../../assets/trust 1.png')}
            overLayColor={'#023047'}
            title={'Trust & Transparency'}
            detail={`We do the due
          diligence for you-vetting impact campaigns and ensuring transparency with reporting of impact outcomes.`}
            fill={'#FCE887'}
          />
        </div>
      </MainContainer>
      <div className='whatDoWeDoBottomQuotation'>
        <img src={require('../../../assets/coma.png')} alt='' srcSet='' />
        <h3>
          "87 percent of consumers say they would purchase a product from a
          company that 'stood up or advocated for' an issue they care about."
          <span>-Deloitte</span>
        </h3>
      </div>
    </>
  );
};

export default WhatDoWeDo;
