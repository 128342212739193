import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { HalfColoredContainer } from '../../global/styles/style-component';
import './Popup.css';

const Popup = () => {
  return (
    <HalfColoredContainer id='popUpContainer' color='#8ECAE6'>
      <div>
        <IconButton>
          <CloseIcon />
        </IconButton>
        <div className='popUpHeader'>
          <h2>WELCOME TO IMPACTOVERSE!</h2>
          <h4>
            We love that you are on board with our mission to scale impact!
          </h4>
        </div>
        <div className='popUpContent'>
          <span>
            If you would like to launch a campaign, and have already
            successfully registered as a Brand, Agency, Non-Profit or Cause,
            please apply to get your campaign approved{' '}
            <Link to='/vetting'>HERE</Link>.
            <br />
            <br />
            You can also do this through your dashboard so head back to it
            anytime!
          </span>
        </div>
      </div>
    </HalfColoredContainer>
  );
};

export default Popup;
