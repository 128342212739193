import React, { useState } from 'react';
import {
  HalfColoredContainer,
  Heading,
} from '../../global/styles/style-component';
import { successToast } from '../../utils/toast';
import Feasibility from './components/Feasibility';
import IdentityVerification from './components/IdentityVerification';
import Impact from './components/Impact';
import OverView from './components/OverView';
import Reference from './components/Reference';
import Transparency from './components/Transparency';
import { Tab, TabNumber } from './vetting-style';
import './Vetting.css';

const Vetting = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const onClickHandler = ({ data }) => {
    console.log(activeIndex);
    if (activeIndex < list.length - 1) {
      setData((prev) => ({ ...prev, data }));
      setActiveIndex((prev) => prev + 1);
    } else {
      successToast('Successfully Register');
    }
  };
  const list = [
    {
      title: 'Identity & Verification',
      component: <IdentityVerification onClickHandler={onClickHandler} />,
    },
    {
      title: 'Overview',
      component: <OverView onClickHandler={onClickHandler} />,
    },
    {
      title: 'Impact',
      component: <Impact onClickHandler={onClickHandler} />,
    },
    {
      title: 'Transparency',
      component: <Transparency onClickHandler={onClickHandler} />,
    },
    {
      title: 'Feasibility',
      component: <Feasibility onClickHandler={onClickHandler} />,
    },
    {
      title: 'Reference',
      component: <Reference onClickHandler={onClickHandler} />,
    },
  ];

  const [data, setData] = useState();

  return (
    <HalfColoredContainer color='#219ebc' className='vettingContainer'>
      <img src={require('../../assets/logo.png')} alt='' />
      <Heading>CAMPAIGN INFORMATION</Heading>
      <div className='vettingContent'>
        <div className='vettingContentHeader'>
          {list.map((item, index) => (
            <div className='vettingHeaderItem' key={index}>
              <Tab
                onClick={() => setActiveIndex(index)}
                active={activeIndex === index}
              >
                <TabNumber>{index + 1}</TabNumber>
                <span>{item.title}</span>
              </Tab>
            </div>
          ))}
        </div>
        {list[activeIndex].component}
      </div>
    </HalfColoredContainer>
  );
};

export default Vetting;
