import React from 'react';
import { CommonBtn } from '../../../global/styles/style-component';
import { FieldContainer, FieldItem } from '../vetting-style';

const Reference = ({ onClickHandler }) => {
  return (
    <FieldContainer noOfColumn={1}>
      <FieldItem>
        <span>1 - Please provide a personal reference contact</span>
        <input type='text' name='name' id='name' />
      </FieldItem>
      <FieldItem>
        <span>2- Please provide a project related professional contact</span>
        <input type='text' name='name' id='name' />
      </FieldItem>

      <div>
        <CommonBtn onClick={() => onClickHandler({})}>submit</CommonBtn>
      </div>
    </FieldContainer>
  );
};

export default Reference;
