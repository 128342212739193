import React from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '../Container';
import Hero from './components/Hero';
import HowDoWeDo from './components/HowDoWeDo';
import Impactoverse from './components/Impactoverse';
import MarketplaceTimer from './components/MarketplaceTimer';
import NftCampaigns from './components/NftCampaigns';
import OurPartners from './components/OurPartners';
import ShowCase from './components/ShowCase';
import WhatDoWeDo from './components/WhatDoWeDo';

const Landing = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Hero />
      <WhatDoWeDo />
      <HowDoWeDo />
      <Impactoverse />
      <MarketplaceTimer />
      <NftCampaigns />
      <ShowCase />
      <OurPartners />
    </Container>
  );
};

export default Landing;
