import React from 'react';
import Footer from '../global/components/Footer/Footer';
import Header from '../global/components/Header/Header';

const Container = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default Container;
