import React from 'react';
import { CommonBtn } from '../../../global/styles/style-component';
import { FieldContainer, FieldItem } from '../vetting-style';

const Impact = ({ onClickHandler }) => {
  return (
    <FieldContainer noOfColumn={2}>
      <FieldItem>
        <span>1- What SDGs does your project address?</span>
        <select>
          <option value='none'>No Poverty</option>
        </select>
      </FieldItem>
      <FieldItem>
        <span>2- Explain the tangible need for this project</span>
        <input type='text' name='name' id='name' placeholder='Write here' />
      </FieldItem>
      <FieldItem>
        <span>3- How will it positively impact people?</span>
        <input type='text' name='name' id='name' placeholder='Write here' />
      </FieldItem>
      <FieldItem>
        <span>4- How will it positively impact the planet?</span>
        <input type='text' name='name' id='name' placeholder='Write here' />
      </FieldItem>
      <FieldItem>
        <span>5- What are your KPIs</span>
        <input type='text' name='name' id='name' placeholder='Write here' />
      </FieldItem>
      <FieldItem>
        <span>
          6- Do you agree to share opportunities to collaborate to scale impact?
        </span>
        <div className='radioContainer'>
          <div>
            <input type='radio' id='yes' name='decision' value='yes' />
            <label htmlFor='yes'>Yes</label>
          </div>
          <div>
            <input type='radio' id='no' name='decision' value='no' />
            <label htmlFor='no'>No</label>
          </div>
        </div>
      </FieldItem>
      <div>
        <CommonBtn onClick={() => onClickHandler({})}>Next</CommonBtn>
      </div>
    </FieldContainer>
  );
};

export default Impact;
