import React from 'react';
import {
  CommonBtn,
  Heading,
  MainContainer,
} from '../../../global/styles/style-component';
import './NftCompaigns.css';

const NftCampaigns = () => {
  return (
    <MainContainer id='nftCampaignsContainer' paddingVertical='3em'>
      <Heading>NFT CAMPAIGNS</Heading>

      <div className='nftCampaignContainer'>
        <div className='nftCompaignLeft'>
          <h2
            className='text-left my-3'
            style={{ color: '#FB8501', textTransform: 'uppercase' }}
          >
            Our Featured Campaign
          </h2>
          <div>
            <h3>PetDrops!</h3>
            <div>
              <img src={require('../../../assets/image 8 (1).png')} alt='' />
              <span>Helping superhero pets save animals in need.</span>
            </div>
            <span>
              Petdrops is a fun, safe way to create and learn about NFTs,
              immortalize your pet on the blockchain, and help animals in need.{' '}
            </span>
          </div>
          <CommonBtn id='learnMoreBtn' margin='2em 0'>
            Learn More
          </CommonBtn>
        </div>
        <div className='nftCompaignRight'>
          <img src={require('../../../assets/image 11.png')} alt='' />
          <div>
            <img src={require('../../../assets/Mask group.png')} alt='' />
            <img src={require('../../../assets/Mask group (1).png')} alt='' />
            <div>
              <img src={require('../../../assets/Mask group (2).png')} alt='' />
            </div>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

export default NftCampaigns;
