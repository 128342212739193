import { faAngleDown, faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuItem } from '@mui/material';
import React, { useLayoutEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../../assets/logo.png';
import { Btn, MainContainer } from '../../styles/style-component';
import './Header.css';

const Header = () => {
  const navigate = useNavigate();
  const [isScroll, setIsScroll] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onScroll = () => {
    if (window.scrollY > 50 && window.scrollY < 100) {
      setIsScroll(true);
    } else if (window.scrollY < 50) {
      setIsScroll(false);
    }
  };

  useLayoutEffect(() => {
    function updateSize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    window.addEventListener('scroll', onScroll);
    updateSize();
    return () => {
      window.removeEventListener('resize', updateSize);
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <MainContainer
      id='navHeaderContainer'
      bgColor={isScroll ? '#0000003d' : 'transparent'}
      flexDirection='row'
      paddingVertical={isScroll ? '0.7rem' : '1.5rem'}
      justifyContent='space-between'
      alignItem='center'
      position='fixed'
    >
      <div id='menuIcon' onClick={() => setIsOpen(!isOpen)}>
        <FontAwesomeIcon icon={faBars} color='#fb8501' />
      </div>
      <Link id='logoHead' to='/'>
        <img
          src={Logo}
          alt='Logo'
          style={{
            width: isScroll ? '250px' : '350px',
          }}
        />
      </Link>
      {width > 620 || isOpen ? (
        <div className='navItemsContainer'>
          <Link to={'/'} className='navBtn'>
            New to NFTs?
          </Link>
          <Link to='/' className='navBtn'>
            Marketplace
          </Link>
          <div className='navBtn'>
            <div onClick={handleClick}>
              Explore <FontAwesomeIcon icon={faAngleDown} color='#fff' />
            </div>
            <Menu
              id='basic-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={handleClose}>Campaigns</MenuItem>
              <MenuItem onClick={handleClose}>Brands</MenuItem>
              <MenuItem onClick={handleClose}>Causes</MenuItem>
              <MenuItem onClick={handleClose}>Creators</MenuItem>
              <MenuItem onClick={handleClose}>PetDropsNft</MenuItem>
            </Menu>
          </div>

          <Btn
            id='loginBtn'
            width='fit-content'
            fontSize='14px'
            onClick={() => {
              navigate('/login');
            }}
          >
            SIGN UP/LOG IN
          </Btn>
        </div>
      ) : null}
    </MainContainer>
  );
};

export default Header;
