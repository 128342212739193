import axios from 'axios';
import { errorToast } from '../utils/toast';

const ApiCall = axios.create({
  baseURL: 'http://localhost:8000/api',
});

ApiCall.interceptors.request.use((request) => {
  document.querySelector('.overlay').style.display = 'block';
  // request.auth =
  return request;
});

ApiCall.interceptors.response.use(
  (response) => {
    document.querySelector('.overlay').style.display = 'none';
    return response;
  },
  (error) => {
    document.querySelector('.overlay').style.display = 'none';
    errorToast(error.response.data.message);
    return Promise.reject(error);
  }
);

export default ApiCall;
