import React, { useRef } from 'react';
import { CommonBtn } from '../../../global/styles/style-component';
import { FieldContainer, FieldItem } from '../vetting-style';

const IdentityVerification = ({ onClickHandler }) => {
  const identityRef = useRef(null);
  const utilityRef = useRef(null);

  return (
    <FieldContainer noOfColumn={2}>
      <FieldItem>
        <span>
          1- Please confirm that you are able to act on behalf of the project
        </span>
        <div className='radioContainer'>
          <div>
            <input type='radio' id='yes' name='decision' value='yes' />
            <label htmlFor='yes'>Yes</label>
          </div>
          <div>
            <input type='radio' id='no' name='decision' value='no' />
            <label htmlFor='no'>No</label>
          </div>
        </div>
      </FieldItem>
      <FieldItem>
        <span>2- Please confirm your name (first name and last name)</span>
        <input type='text' name='name' id='name' />
      </FieldItem>
      <FieldItem>
        <span>3- Please upload proof of identity</span>
        <select>
          <option value='none'>Passport</option>
        </select>
        <div
          onClick={() => {
            identityRef.current.click();
          }}
          className='uploadBtn'
        >
          <input
            ref={identityRef}
            type='file'
            style={{
              display: 'none',
            }}
          />
        </div>
      </FieldItem>
      <FieldItem>
        <span>4- Please upload proof of address</span>
        <select>
          <option value='none'>Utility Bills</option>
        </select>
        <div
          onClick={() => {
            utilityRef.current.click();
          }}
          className='uploadBtn'
        >
          <input
            ref={utilityRef}
            type='file'
            style={{
              display: 'none',
            }}
          />
        </div>
      </FieldItem>
      <div>
        <CommonBtn onClick={() => onClickHandler({})}>Next</CommonBtn>
      </div>
    </FieldContainer>
  );
};

export default IdentityVerification;
