import React from 'react';
import startup1 from '../../../assets/startup 1.png';
import startup2 from '../../../assets/startup 2.png';
import startup3 from '../../../assets/startup 3.png';
import startup4 from '../../../assets/startup 4.png';
import startup5 from '../../../assets/startup 5.png';
import rightArrow from '../../../assets/Arrow 1.png';
import {
  BoxShadow,
  Heading,
  MainContainer,
} from '../../../global/styles/style-component';
import './HowDoWeDo.css';
import { CardOverLay } from '../../../global/components/Card/card-style';

const HowDoWeDo = () => {
  return (
    <MainContainer paddingVertical={'3em'}>
      <BoxShadow>
        <div className='howDoWeDoContainer'>
          <CardOverLay color={'#FB8501'} />
          <Heading>HOW DO WE DO THIS?</Heading>

          <div className='howDoWeDoCardsContainer'>
            <div>
              <div>
                <img src={startup1} alt='' />
                <span>VETTED INITIATIVES</span>
              </div>
              <div>
                <img src={startup4} alt='' />
                <span>COMMUNITY BUILDING</span>
              </div>
              <div>
                <img src={startup2} alt='' />
                <span>NFT MARKETPLACE</span>
              </div>

              <div>
                <img src={startup5} alt='' />
                <span>REPORTING</span>
              </div>
              <div>
                <img src={startup3} alt='' />
                <span>COLLABORATIONS</span>
              </div>
            </div>
            <div>
              <div>
                <img src={startup4} alt='' />
                <span>COMMUNITY BUILDING</span>
              </div>
              <div>
                <img src={startup5} alt='' />
                <span>REPORTING</span>
              </div>
            </div>
          </div>

          <div className='howDoWeDoBtnContainer'>
            <button>DONE FOR YOUR SERVICE</button>
            <div>
              <span>Find Out More</span>
              <img src={rightArrow} alt='' />
            </div>
          </div>
        </div>
      </BoxShadow>
    </MainContainer>
  );
};

export default HowDoWeDo;
