import React from 'react';
import { CommonBtn } from '../../../global/styles/style-component';
import { FieldContainer, FieldItem } from '../vetting-style';

const OverView = ({ onClickHandler }) => {
  return (
    <FieldContainer noOfColumn={2}>
      <FieldItem>
        <span>1- Describe your campaign</span>
        <textarea name='compaigh' id='' rows='5' />
      </FieldItem>
      <FieldItem>
        <span>What is the timeline for delivery</span>
        <select>
          <option value='none'>Choose timeline</option>
        </select>
      </FieldItem>
      <FieldItem>
        <span>When does your project aim to start</span>
        <select>
          <option value='none'>Choose Date</option>
        </select>
      </FieldItem>
      <div>
        <CommonBtn onClick={() => onClickHandler({})}>Next</CommonBtn>
      </div>
    </FieldContainer>
  );
};

export default OverView;
