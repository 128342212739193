import React from 'react';
import { Link } from 'react-router-dom';
import { Btn, MainContainer } from '../../styles/style-component';
import './Footer.css';

const Footer = () => {
  return (
    <div className='footerContainer'>
      <MainContainer bgColor='transparent'>
        <div className='footer'>
          <div className='footerLinks'>
            <strong className='footerHeader'>Get our updates</strong>
            <div className='footerForm'>
              <label htmlFor='firstName'>First name</label>
              <input type='text' name='firstName' id='firstName' />
              <label htmlFor='lastName'>Last name</label>
              <input type='text' name='lastName' id='lastName' />
              <label htmlFor='email'>Email</label>
              <input type='email' name='email' id='email' />
              <Btn id='footerBtn' marginLeft='0'>
                EMAIL OTP IN
              </Btn>
            </div>
          </div>
          <div className='footerLinks'>
            <strong className='footerHeader'>Explore</strong>
            <div>
              <Link to='/'>For Creators & Supporters</Link>
              <Link to='/'>For Brands</Link>
              <Link to='/'>For NPOs & Causes</Link>
              <Link to='/'>DIY campaigns</Link>
              <Link to='/'>DFY campaigns</Link>
            </div>
          </div>
          <div className='footerLinks'>
            <strong className='footerHeader'></strong>

            <div>
              <Link to='/'>NFT How-to Hub</Link>
              <Link to='/'>FAQs</Link>
              <Link to='/'>Our Road map</Link>
              <Link to='/'>Our Technologies</Link>
              <Link to='/'>Our Team</Link>
              <Link to='/'>Contact Us</Link>
            </div>
          </div>
        </div>
      </MainContainer>
      <div className='footerCopyWrite'>
        <strong>© Copyright - link to T&C</strong>
      </div>
    </div>
  );
};

export default Footer;
