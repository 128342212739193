import React from 'react';
import { CommonBtn } from '../../../global/styles/style-component';
import {
  FieldContainer,
  FieldInnerContainer,
  FieldItem,
} from '../vetting-style';

const Transparency = ({ onClickHandler }) => {
  return (
    <FieldContainer noOfColumn={1}>
      <FieldItem>
        <span>
          1- Do you agree to reporting on activity and providing evidence of
          impact? (reports on numbers impacted, activities, photo & video
          evidence, testimonials)
        </span>
        <div className='radioContainer'>
          <div>
            <input type='radio' id='yes' name='decision' value='yes' />
            <label htmlFor='yes'>Yes</label>
          </div>
          <div>
            <input type='radio' id='no' name='decision' value='no' />
            <label htmlFor='no'>No</label>
          </div>
        </div>
      </FieldItem>

      <FieldInnerContainer>
        <span>
          2- List additional team members working on this campaign (names, links
          to bio, mails, phone)
        </span>
        <div>
          <FieldItem icon={'\f406'}>
            <span>Name</span>
            <input type='text' name='name' id='name' placeholder='Full name' />
          </FieldItem>
          <FieldItem icon={`\f0c1`}>
            <span>Bio Link</span>
            <input type='text' name='name' id='name' placeholder='Link' />
          </FieldItem>
          <FieldItem icon={'\f0e0'}>
            <span>Mailing Address</span>
            <input type='email' name='name' id='name' placeholder='Email' />
          </FieldItem>
          <FieldItem icon={'\f095'}>
            <span>Phone Number</span>
            <input
              type='phone'
              name='name'
              id='name'
              placeholder='Phone Number'
            />
          </FieldItem>
        </div>
      </FieldInnerContainer>
      <FieldItem>
        <span>
          Do you agree to share lessons learned to support knowledge sharing for
          others having an impact?
        </span>
        <div className='radioContainer'>
          <div>
            <input type='radio' id='yes' name='decision' value='yes' />
            <label htmlFor='yes'>Yes</label>
          </div>
          <div>
            <input type='radio' id='no' name='decision' value='no' />
            <label htmlFor='no'>No</label>
          </div>
        </div>
      </FieldItem>
      <div>
        <CommonBtn onClick={() => onClickHandler({})}>Next</CommonBtn>
      </div>
    </FieldContainer>
  );
};

export default Transparency;
