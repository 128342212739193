import React from 'react';
import {
  CommonBtn,
  Heading,
  MainContainer,
} from '../../../global/styles/style-component';
import './MarketPlaceTimer.css';

const MarketplaceTimer = () => {
  return (
    <MainContainer
      id='marketPlaceTimer'
      alignItem='center'
      paddingVertical='3em'
      bgColor='#d9d9d93a'
    >
      <Heading>
        IMPACTOVERSE MARKETPLACE <br /> LAUNCHING SOON
      </Heading>

      <h4>Sign up for updates from mission control!</h4>

      <CommonBtn margin='2em 0 2em 0'>email opt in</CommonBtn>
      {/* days */}
      <div className='marketPlaceTimerContainer'>
        <div>
          <div>
            <div className='timer'>1</div>
            <div className='timer'>0</div>
            <h3 className='timerDivider'>:</h3>
          </div>
          <p>Days</p>
        </div>

        {/* hours */}
        <div>
          <div>
            <div className='timer'>1</div>
            <div className='timer'>9</div>
            <h3 className='timerDivider'>:</h3>
          </div>
          <p>Hours</p>
        </div>
        {/* minutes */}
        <div>
          <div>
            <div className='timer'>5</div>
            <div className='timer'>8</div>
          </div>
          <p>Minutes</p>
        </div>
      </div>
    </MainContainer>
  );
};

export default MarketplaceTimer;
