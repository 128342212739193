import React from 'react';
import background from '../../../assets/Sea Of Clouds.mp4';
import { MainContainer } from '../../../global/styles/style-component';
import './Hero.css';
const Hero = () => {
  return (
    <div className='heroContainer'>
      <video autoPlay muted loop id='myVideo'>
        <source src={background} type='video/mp4' />
      </video>
      <MainContainer width='100%' justifyContent='flex-end'>
        <h1>
          Collabs To <br />
          Scale Impact
        </h1>
      </MainContainer>
    </div>
  );
};

export default Hero;
