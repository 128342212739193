import React from 'react';
import { CommonBtn } from '../../../global/styles/style-component';
import { FieldContainer, FieldItem } from '../vetting-style';

const Feasibility = ({ onClickHandler }) => {
  return (
    <FieldContainer noOfColumn={1}>
      <FieldItem>
        <span>
          1- What operational capacity do you have to be able to deliver on this
          campaign?
        </span>
        <input type='text' name='name' id='name' placeholder='Write here' />
      </FieldItem>
      <FieldItem>
        <span>2- What circumstances would.... ?</span>
        <textarea name='compaigh' id='' rows='5' />
      </FieldItem>
      <div>
        <CommonBtn onClick={() => onClickHandler({})}>Next</CommonBtn>
      </div>
    </FieldContainer>
  );
};

export default Feasibility;
