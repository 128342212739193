import styled from 'styled-components';

export const Card = styled.div`
  position: relative;
  width: 300px;
  display: flex;
  background-image: url(${({ bg }) => bg});
  background-size: cover;
  background-position: center;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.68) 100%
    );
  }

  & > div {
    width: 100%;
    padding: 1em;
    align-self: flex-end;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-transform: capitalize;
    color: #fff;
    & > button {
      width: fit-content;
      padding: 5px 7px;
      background: transparent;
      border-radius: 5px;
      border: 1px solid white;
      color: white;
      text-transform: capitalize;
      margin-top: 10px;
      font-size: 12px;
    }
  }
`;
