import React, { useState } from 'react';
import LoginWithFacebook from '../../global/components/LoginWithFacebook';
import LoginWithGoogle from '../../global/components/LoginWithGoogle';
import { useDispatch } from 'react-redux';
import { userLogin } from '../../redux/user/actionCreator';
import { useNavigate } from 'react-router-dom';
import './Login.css';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({
    email: '',
    password: '',
    provider: 'email',
  });
  const onLogin = (e) => {
    e.preventDefault();
    // dispatch(userLogin(data, navigate));
    navigate('/popup');
  };
  return (
    <div className='loginContainer'>
      <div className='loginLeft'>
        <div>
          <div>
            <img
              src={require('../../assets/loginInWhite.png')}
              alt=''
              srcSet=''
            />
            <span>
              Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam
              Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore Magna Aliquyam
              Erat, Sed Diam Voluptua. Lorem Ipsum Dolor Sit Amet
            </span>
          </div>
          <div>
            <h3>Let's Get Started</h3>
            <img src={require('../../assets/rightIcon.png')} alt='' srcSet='' />
          </div>
        </div>
      </div>
      <div className='loginRight'>
        <div>
          <h2>Welcome Back </h2>
          <h5>
            Don't have account?{' '}
            <span onClick={() => navigate('/registration')}>SIGN UP</span>
          </h5>
          <form>
            <label htmlFor='email'>Email/Username</label>
            <input
              type='email'
              name='email'
              id='email'
              placeholder='Email'
              onChange={(e) => setData({ ...data, email: e.target.value })}
            />
            <label htmlFor='password'>Password</label>
            <input
              type='password'
              name='password'
              id='password'
              onChange={(e) => setData({ ...data, password: e.target.value })}
              placeholder='Password'
            />
            <span>Forgot Password?</span>
            <button onClick={onLogin}>SIGN IN</button>
          </form>
          <div className='loginLine'>
            <hr />
          </div>
          <div className='loginBtnContainer'>
            <LoginWithGoogle method={'Sign In'} />
            <LoginWithFacebook method={'Sign In'} />
            <div>
              <img src={require('../../assets/wallet.png')} alt='' />
              <button>Sign In With Wallet</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
