import AxiosCall from '../../utils/axiosCall';
import { errorToast, successToast } from '../../utils/toast';
import { setUser } from './userReducer';

export const userLogin = (user, navigate) => (dispatch) => {
  AxiosCall.post('/user/login', user)
    .then((res) => {
      if (res.data.success) {
        dispatch(setUser(res.data.data));
        console.log(res.data.data);
        successToast('Login Successful');
      } else {
        errorToast(res.data.message);
      }
    })
    .catch((err) => {
      console.log(err);
      errorToast(err.response.data.message);
    });
};

export const userRegister = (user, navigate) => (dispatch) => {
  AxiosCall.post('/user/register', {
    email: user.email,
    password: user.password,
    name: user.name,
    provider: user.provider,
  })
    .then((res) => {
      if (res.data.success) {
        successToast(' Registration Successful. Please Login.');
      } else {
        errorToast(res.data.message);
      }
    })
    .catch((err) => {
      errorToast(err.response.data.message);
    });
};
